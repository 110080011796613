.container{

    padding: 20px 40px;
    height: 100vh;
    

}
.inner{

}
.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.btn-booking{
    padding: 20px;
    border: 2px solid rgb(2, 53, 73);
    display: inline-block;
    min-width: 200px;
    background-color: lightcyan;
    cursor: pointer;


}
.btn-booking:hover{
    background-color: rgb(24, 162, 162);
}