.filter-container {
  min-height: 100vh;
  background: url(../Images/plane-841441_1920.jpg);
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative
}

.result-container {
  height: 100vh;
  background: url(../Images/plane-841441_1920.jpg);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative
}

.book-container {
  height: 100vh;
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 1);
}

.CardComponent {
  margin-top: 20px;
}

